import React, { Suspense, lazy, useEffect } from "react";
import Routes from "./router/Routes";
import AOS from "aos";
import "aos/dist/aos.css";

/* mr */

/*
const Home = lazy(() => import('./components/Home'));
const About = lazy(() => import('./components/About'));
const Portfolio = lazy(() => import('./components/PortfolioCreative'));
const Contact = lazy(() => import('./components/Contact'));

const Routes = lazy(() => import('./router/Routes'));
*/

/*end mr*/

const App = () => {
  
  useEffect(() => {
    AOS.init();
  }, []);
  
  return (
    /*<Suspense fallback = {<div>LOADING</div>}>*/

    <div className="tokyo_tm_all_wrap">
      <Routes />
    </div>

    /*</Suspense>*/
  ); /*this works*/
 
};

export default App;
