/** Acknowledgement: theme purchased and modified from Ib-Themes: https://themeforest.net/user/ib-themes ; name: Tokyo */
//import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

/*import Home from "../../components/Home";
import About from "../../components/About";
import Portfolio from "../../components/PortfolioCreative";*/

//import News from "../../components/News";

//import Contact from "../../components/Contact";

/* mr */

import React, { Suspense, lazy, useEffect } from "react";

const Home = lazy(() => import('../../components/Home'));
const About = lazy(() => import('../../components/About'));
const Portfolio = lazy(() => import('../../components/PortfolioCreative'));
const Contact = lazy(() => import('../../components/Contact'));

/* end mr */

const HomeLight = () => {
  return (
    
    <>
    <Suspense fallback={<div>LOADING</div>}>
        <Tabs>
          <TabList>
            {/* START LEFT MENU CONTENT */}
            <div className="leftpart">
              <div className="leftpart_inner">
                <div className="logo">
                  <Link className="navbar-brand" to="/">
                    <img className="svgNav" src="/assets/img/logo/sigBlack.svg" alt="Routtstanding" />
                  </Link>
                </div>
                {/* END LOGO */}

                <div className="menu">
                  <ul>
                    <Tab>
                      <img
                        className="svg"
                        src="/assets/img/svg/house-chimney-solid.svg"
                        alt="homerun"
                      />
                      <span className="menu_content">Home</span>
                    </Tab>
                    <Tab>
                      <img
                        className="svg"
                        src="/assets/img/svg/user-solid.svg"
                        alt="avatar"
                      />
                      <span className="menu_content">About</span>
                    </Tab>
                    <Tab>
                      <img
                        className="svg"
                        src="/assets/img/svg/briefcase-solid.svg"
                        alt="briefcase"
                      />
                      <span className="menu_content">Portfolio</span>
                    </Tab>
                    
                    

                    <Tab>
                      <img
                        className="svg"
                        src="/assets/img/svg/envelope-solid.svg"
                        alt="mail"
                      />
                      <span className="menu_content">Contact</span>
                    </Tab>
                  </ul>
                </div>
                {/* END MENU */}

              
                <div className="copyright">
                  <p>
                    &copy; {new Date().getFullYear()} Michelle Routt                 
                  </p>
                </div>
                {/* END COPYRIGHT */}
              </div>
            </div>
            {/* END LEFT MENU CONTENT */}
          </TabList>
          {/* END SIDEBAR TABLIST */}

          {/* START RIGHT PART CONTENT */}
          <div className="rightpart">
            <div className="rightpart_in">
              <div className="tokyo_tm_section">
                <div className="container">
                  
                  
                  <TabPanel>
                    <div data-aos="fade-right" data-aos-duration="1200">
                      <Home />
                    </div>
                  </TabPanel>
                  {/* END HOME MENU TAB CONTENT */}

                  <Suspense fallback={<div/>}>
                    <TabPanel>
                      <div
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="100"
                      >
                        <About />
                      </div>
                    </TabPanel>
                    {/* END ABOUT MENU TAB CONTENT */}

                    <TabPanel>
                      <Portfolio />
                    </TabPanel>
                    {/* END PORTFOLIO MENU TAB CONTENT */}

                    

                    <TabPanel>
                      <div
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <Contact />
                      </div>
                    </TabPanel>
                    {/* END CONTACT MENU TAB CONTENT */}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
          {/* END RIGHT PART CONTENT */}
        </Tabs>
      </Suspense>
      {/* END TABS */}
    </>
  );
};

export default HomeLight;

/** removed from tabs
 * 
 * <TabPanel>
                  <News />
                </TabPanel>
 */

 /** removed from menu
               <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/paper.svg"
                      alt="paper"
                    />
                    <span className="menu_content">News</span>
                  </Tab> */
